/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
html { overflow-y:scroll; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.spacer { flex: 1 1 auto; }
.is-uppercase { text-transform: uppercase; }

button {
  text-transform: uppercase;
}

.month-picker {
  .mat-calendar-period-button {
    pointer-events: none;
  }

  .mat-calendar-arrow {
    display: none;
  }
}

.mat-dialog-container {
  max-height: 100% !important;
}

h4.welcome-title {
  color:  rgb(31, 73, 125);
  margin-top: 0;
  margin-bottom: 8px;
}

p.welcome-hint {
  font-size: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0 16px 16px 16px;
}
